export default {
	apiKey: "AIzaSyClRNOfBugORLEevtpl9sISAtaUI2wIjm8",
	authDomain: "bookmarker-droopy.firebaseapp.com",
	databaseURL: "https://bookmarker-droopy.firebaseio.com",
	projectId: "bookmarker-droopy",
	storageBucket: "bookmarker-droopy.appspot.com",
	messagingSenderId: "15095510243",
	appId: "1:15095510243:web:bd3db6f231d0701ace01d6",
	measurementId: "G-KE306D1H72",
};
